<!--
 * @Description: 用户列表
 * @Author: ChenXueLin
 * @Date: 2021-09-01 09:58:42
 * @LastEditTime: 2022-07-15 16:01:45
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="userCodeList">
              <input-plus
                v-model="searchForm.userCodeList"
                valueType="Array"
                placeholder="用户工号"
                title="用户工号"
                label="用户工号"
                clearable
              ></input-plus>
              <!-- <el-input
                v-model="searchForm.userCode"
                placeholder="用户工号"
                title="用户工号"
              ></el-input> -->
            </el-form-item>
            <el-form-item class="search-item--1" prop="userName">
              <el-input
                v-model="searchForm.userName"
                placeholder="用户名称"
                title="用户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phone">
              <el-input
                v-model="searchForm.phone"
                placeholder="手机号"
                title="手机号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userType">
              <e6-vr-select
                v-model="searchForm.userType"
                :data="userTypeList"
                placeholder="用户类型"
                title="用户类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userRole">
              <e6-vr-select
                v-model="searchForm.userRole"
                :data="roleList"
                placeholder="用户角色"
                title="用户角色"
                clearable
                :props="{
                  id: 'id',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userStatus">
              <e6-vr-select
                v-model="searchForm.userStatus"
                :data="userStatusList"
                placeholder="用户状态"
                title="用户状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="添加用户" @click="handleAdd"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <div class="treeData">
          <e6-tree
            :style="{ height: resizeViewHeight - 25 + 'px' }"
            :list="treeData"
            ref="e6Tree"
            :is-open="true"
            :disabledNoCheck="true"
            @func="handleTreeClick"
          ></e6-tree>
        </div>
        <div class="table-box">
          <el-table
            border
            :height="resizeViewHeight + 'px'"
            :data="tableData"
            highlight-current-row
            class="elTable"
            ref="elTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              align="center"
              label="操作"
              width="120"
            >
              <template slot-scope="scope">
                <e6-td-operate
                  :data="getOperateList()"
                  @command="handleOperate(scope.row, $event)"
                ></e6-td-operate>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加用户弹框 -->
    <add-user-dialog
      :addUserVisible="addUserVisible"
      :roleList="roleList"
      type="add"
      @refreshData="queryList"
      @handleClose="handleClose"
    ></add-user-dialog>
    <!-- 操作日志 -->
    <log-dialog
      :logVisible.sync="logVisible"
      :businessId="clickRow.userId"
      logType="11"
    ></log-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { exportData } from "@/utils/download";
import { printError } from "@/utils/util";
import LogDialog from "@/components/logDialog.vue";
import handleTree from "../mixins/index";
import inputPlus from "@/components/inputPlus";
import {
  getFrameworkTree,
  getUserList,
  batchDisEnable,
  batchEnable,
  findDownList,
  getUserRoleList,
  exportUserList
} from "@/api";
import AddUserDialog from "./addUserDialog.vue";
export default {
  name: "IndividualUser",
  components: { AddUserDialog, LogDialog, inputPlus },
  data() {
    return {
      loading: false,
      total: 0,
      userTypeList: [], //用户类型下拉框
      roleList: [], //用户角色下拉框
      userStatusList: [
        { id: 1, label: "正常" },
        {
          id: 0,
          label: "禁止"
        }
      ], //用户状态
      curFrameworkId: 0, //当前树结构的id
      searchForm: {
        userCode: "", //用户ID
        userCodeList: [],
        userName: "", //用户名称
        phone: "", //手机号
        userType: "", //用户类型
        userRole: "", //
        userStatus: "", //用户状态
        userCorp: "", //所属企业
        userFramework: "", //所属部门
        createdBy: "", //创建人
        createTime: [], //创建时间
        startCreatedTime: "", //开始时间
        endCreatedTime: "", //结束时间
        pageIndex: 1,
        pageSize: 20
      },
      treeData: [],
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户id",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "employeeNo",
          display: true,
          fieldLabel: "用户工号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userCode",
          display: true,
          fieldLabel: "用户账号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "用户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "employeeName",
          display: true,
          fieldLabel: "员工名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "手机号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userType",
          display: true,
          fieldLabel: "用户类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userRole",
          display: true,
          fieldLabel: "用户角色",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userCorp",
          display: true,
          fieldLabel: "所属企业",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userFramework",
          display: true,
          fieldLabel: "所属部门",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userPost",
          display: true,
          fieldLabel: "岗位",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userDuty",
          display: true,
          fieldLabel: "职级",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userStatus",
          display: true,
          fieldLabel: "用户状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stopTimeStr",
          display: true,
          fieldLabel: "禁用时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [], //选中的数据id
      /**添加用户 */
      addUserVisible: false, //添加用户弹框是否显示
      logVisible: false,
      clickRow: {}
    };
  },
  mixins: [base, listPageReszie, listPage, handleTree],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
    this.getTreeData();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        let promiseList = [
          findDownList(["userType"]),
          getUserRoleList([])
          // { id: "" }
        ];
        let [userTypeRes, roleRes] = await Promise.all(promiseList);
        //用户类型下拉框
        this.userTypeList = this.getFreezeResponse(userTypeRes, {
          path: "data.userType"
        });

        //用户角色
        this.roleList = this.getFreezeResponse(roleRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //查询组织架构树
    async getTreeData() {
      try {
        this.loading = true;
        let res = await getFrameworkTree();
        let treeData = res.data;
        if (treeData.id == 0) {
          treeData.pid = null;
        }
        this.handleTreeData(treeData ? [treeData] : []);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 处理树节点点击
    handleTreeClick(node) {
      this.clickInfo = node;
      this.curFrameworkId = node.id;
      this.parentPath = node.path;
      this.curFrameworkName = node.frameworkName;
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    //获取用户列表
    async queryList() {
      try {
        let res = await getUserList({
          ...this.searchForm,
          userFramework: this.curFrameworkId
        });
        this.tableData = res.data.array;
        this.tableData.map(item => {
          item.userRole = item.userRole.join("，");
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选id
    handleSelectionChange(columns) {
      // console.log(columns);
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.userId,
            versionNumber: item.versionNumber
          }
        );
      });
      // console.log(this.selColumnId);
    },
    //处理启用禁用
    changeStatus(status) {
      if (this.selColumnId.length) {
        let tipTitle =
          status == 1 ? "是否确认启用勾选的账号？" : "是否确认禁用勾选的账号？";
        let title = status == 1 ? "启用账号" : "禁用账号";
        this.$confirm(tipTitle, title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (status == 1) {
              this.batchEnable();
            } else {
              this.batchDisEnable();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.$message.warning("未勾选数据");
      }
    },
    //批量启用请求
    async batchEnable() {
      try {
        let res = await batchEnable(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //批量禁用请求
    async batchDisEnable() {
      try {
        let res = await batchDisEnable(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportUserList);
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        },
        {
          label: "操作日志",
          id: 2
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(row, val) {
      if (val.id == 1) {
        this.routerPush({
          name: "userManage/userDetail",
          params: {
            refresh: true,
            userId: row.userId
          }
        });
      }
      if (val.id == 2) {
        this.clickRow = row;
        this.logVisible = true;
      }
    },
    /*****添加用户 */
    handleAdd() {
      this.addUserVisible = true;
    },
    handleClose() {
      this.addUserVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  .treeData {
    width: 280px;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0 0 10px;
  }
  .table-box {
    width: calc(100% - 280px);
    height: 100%;
    border-left: 1px solid #edf0f5;
  }
}
</style>
